<template>
  <b-row>
    <b-col>
      <b-card>

        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="35"
              height="35"
              viewBox="0 0 172 172"
              style=" fill:#000000; cursor: pointer"
              @click="$router.go(-1)"
            ><g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-size="none"
              style="mix-blend-mode: normal"
            ><path
              d="M0,172v-172h172v172z"
              fill="none"
            /><g fill="#08071a"><path d="M86,154.8c37.9948,0 68.8,-30.8052 68.8,-68.8c0,-37.9948 -30.8052,-68.8 -68.8,-68.8c-37.9948,0 -68.8,30.8052 -68.8,68.8c0,37.9948 30.8052,68.8 68.8,68.8zM53.27987,81.94653l22.93333,-22.93333c1.118,-1.118 2.58573,-1.67987 4.05347,-1.67987c1.46773,0 2.93547,0.56187 4.05347,1.67987c2.24173,2.24173 2.24173,5.8652 0,8.10693l-13.14653,13.14653h43.49307c3.1648,0 5.73333,2.5628 5.73333,5.73333c0,3.17053 -2.56853,5.73333 -5.73333,5.73333h-43.49307l13.14653,13.14653c2.24173,2.24173 2.24173,5.8652 0,8.10693c-2.24173,2.24173 -5.8652,2.24173 -8.10693,0l-22.93333,-22.93333c-2.24173,-2.24173 -2.24173,-5.8652 0,-8.10693z" /></g></g></svg>

            <div class="titulo-pagina ml-1">
              <div style="align-self: center">Cadastrar Contrato
              </div>
            </div>
          </div>
        </div>

        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Título do contrato:"
              label-for="titulo_contrato"
            >
              <b-input
                v-model="titulo"
                label="nome"
                placeholder="Ex: Contrato Escolvix"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Selecione o cliente :"
              label-for="cliente"
            >
              <v-select
                v-model="cliente"
                label="nome"
                placeholder="Cliente"
                :options="opcoescliente"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group

              label="Número do contrato :"
              label-for="numero_contrato"
            >
              <b-form-input
                id="numero_contrato"
                v-model="numero_contrato"
                placeholder="Numero do contrato"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group

              label="Obras do contrato:"
              label-for="obras_selecionadas"
            >
              <multiselect
                v-model="value"
                label="nome"
                :multiple="true"
                :options="lista_obras"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col>
            <div style="display: flex">
              <b-button
                variant="primary"
                class="px-3"
                @click="SalvaContrato()"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                Salvar Contrato
              </b-button>
              <div
                style="align-self: center; cursor: pointer"
                class="ml-2"
                variant="primary"
                block
                @click="LimparCampos()"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                Limpar Campos
              </div>
            </div>
          </b-col>

        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexContrato',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      dir: 'ltr',
      value: null,
      tituloContrato: '',
      url: 'admin/contrato',
      data: {},
      // ---------- Dados V-Model ----------- \\
      id: null,
      titulo: null,
      numero_contrato: null,
      descricao: null,
      cliente: null,
      opcoescliente: [],
      lista_obras: [],
    }
  },

  async created() {
    if (this.$route.name === 'adm-contrato-ver') {
      this.BuscarCliente()
      this.BuscarDados(this.url)
      await this.$http.get('admin/cliente').then(cliente => {
        const clientesBanco = cliente.data.data
        const clientes = []
        clientesBanco.forEach(c => {
          clientes.push({
            value: c.id,
            name: c.nome,
          })
        })
        this.opcoescliente = clientes
      })
      await this.$http.get('admin/obra').then(obras => {
        this.lista_obras = obras.data.data
      })
    }
  },
  methods: {
    async BuscarCliente() {
      this.$loading(true)
      this.edicao = JSON.parse(localStorage.getItem('admin/edicao'))
      if (this.edicao) {
        this.clienteeditar = JSON.parse(localStorage.getItem('admin/edicao'))
        this.id = this.clienteeditar.id
        this.titulo = this.clienteeditar.titulo
        this.numero_contrato = this.clienteeditar.numero_contrato
        this.descricao = this.clienteeditar.descricao
        await this.$http.get(`admin/cliente_buscar/${this.clienteeditar.cliente_id}`).then(clientes => {
          this.cliente = clientes.data
        })
        this.$loading(false)
      }
      if (!this.edicao) {
        this.clientes = JSON.parse(localStorage.getItem('admin/clientes'))
        this.cliente = this.clientes
        this.$loading(false)
      }
    },
    BuscarDados(url = this.url) {
      this.$http.get(url)
        .then(resp => {
          this.data = resp.data.data
        })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    LimparCampos() {
      this.id = null
      this.titulo = null
      this.numero_contrato = null
      this.descricao = null
      this.cliente = null
    },
    async SalvaContrato() {
      const obj = {
        numero_contrato: this.numero_contrato,
        titulo: this.titulo,
        descricao: this.descricao,
        cliente_id: this.cliente.id,
      }
      this.$loading(true)
      if (this.id === null) {
        await this.$http.post('admin/contrato', obj).then(resp => {
          localStorage.setItem('admin/contratos', JSON.stringify(resp))
          this.$loading(false)
          this.$router.push({
            name: 'adm-obra-ver',
            params: {
              id: resp.data.contrato.id,
            },
          })
        })
      }
      if (this.id) {
        this.$http.put(`admin/contrato/${this.id}`, obj).then(resp => {
          if (resp.data.success) {
            this.Notificacao('success', resp.data.success)
            this.$loading(false)
            this.$router.push({
              name: 'adm-contrato',
            })
          }
          if (resp.data.error) {
            this.Notificacao('danger', resp.data.error)
            this.$loading(false)
          }
        })
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

</style>
